'use client';

import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@v2/utils/cn';

const labelVariants = cva(
  ' text-xs md:text-md text-gray-500 font-medium leading-none peer-disabled:cursor-not-allowed '
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> & {
      isRequired?: boolean;
    }
>(({ className, isRequired, children, ...props }, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn('relative', labelVariants(), className)}
    {...props}
  >
    {children}
    {isRequired && <span className="absolute text-primary-500">*</span>}
  </LabelPrimitive.Root>
));
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };

export enum USER_ROLE {
  superAdmin = 0,
  admin = 1,
  user = 2,
  affiliate = 3,
  agency = 4,
  agencyMember = 5,
  individualAgent = 6,
}


export const USER_ROLE_LABELS: { [key: number]: string } = {
  [USER_ROLE.superAdmin]: 'Super Admin',
  [USER_ROLE.admin]: 'Admin',
  [USER_ROLE.user]: 'User',
  [USER_ROLE.affiliate]: 'Affiliate',
  [USER_ROLE.agency]: 'Agency',
  [USER_ROLE.agencyMember]: 'Agency Member',
  [USER_ROLE.individualAgent]: 'Individual Agent',
};
